import { Box, Heading } from '@chakra-ui/react'
import React from 'react'
import { ContentButton, SectionBlock } from 'starterUiComponents'
import { graphql } from 'gatsby'
const Terms = (props) => {
  const {
    data: {
      page: {
        flexLayouts: { flexibleLayouts },
      },
    },
  } = props

  return flexibleLayouts?.map((card) => {
    const { anchor, cssclass, title, content, button } = card
    const fontSize = cssclass?.includes('textSmall') ? 'sm' : 'base'
    return (
      <SectionBlock
        anchor={anchor}
        cssclass={cssclass}
        sx={{ ...style }}
        {...props}
      >
        <Box className="px-4 md:px-0">
          {title && (
            <Heading
              dangerouslySetInnerHTML={{ __html: title }}
              fontSize="l"
              lineHeight={1.33}
              mb={10}
            />
          )}
          {content && (
            <Box
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
              sx={{ p: { fontSize } }}
            />
          )}
          {button && <ContentButton button={button} />}
        </Box>
      </SectionBlock>
    )
  })
}
const style = {
  '&.contentTwoCols': {
    mb: 7,
    '.content': {
      display: { base: 'block', xl: 'flex' },
    },

    '.left': {
      width: { base: '100%', xl: '50%' },
    },
    h4: {
      fontSize: 'l',
      mb: 7,
    },
    '.right': {
      display: 'flex',
      justifyContent: 'center',
      width: { base: '100%', xl: '50%' },
      img: { height: '95% !important' },
    },
  },
  '&.pp': {
    h3: {
      textTransform: 'lowercase',
      mb: 2,
    },
  },
}

export const termsPageQuery = graphql`
  query WpPageTermsTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      flexLayouts {
        flexibleLayouts {
          ... on WpPage_Flexlayouts_FlexibleLayouts_ContentBlock {
            title
            content
            cssclass
            anchor
            button {
              target
              title
              url
            }
          }
        }
      }
    }
  }
`

export default Terms
